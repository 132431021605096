.view {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  text-align: center;
}

.header {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100px;
  z-index: 100;
  overflow: hidden;
  background-color: #DD2C56;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 0 40px 0;
  overflow-x: hidden;
  overflow-y: visible;
  z-index: 1;
  align-items: center;
  text-align: center;
}

.footer {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  z-index: 100;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px 0 10px;
}