.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  padding: 0;
  margin: 0 0 0 10px;
  &:first-child {
    margin-left: 0;
  }
}