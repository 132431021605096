.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 0 20px;
  max-width: 780px;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  > h2 {
    margin-bottom: 100px;
    > em {
      text-decoration: underline;
      font-style: normal;
    }
  }
}
