.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 760px;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  
  > h2 {
    margin-bottom: 50px;
  }

  > a {
    margin-bottom: 50px;
  }
}

.instructions {
  margin-top: 30px;
}