.Button {
  display: inline-flex;
  position: relative;
  border-radius: 0;
  background-color: white;
  color: #DD2C56;
  border: 0;
  padding: 0 30px;
  font-weight: 400;
  line-height: 2.4rem;
  font-size: 2.0rem;
  height: 60px;
  box-shadow: none;
  outline: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  appearance: none;
  cursor: pointer;
  text-decoration: none;
  transition: all 250ms ease 0ms;
  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:hover {
    background-color:rgba(255, 255, 255, 0.9);
    text-decoration: none;
  }
  &:focus {
    background-color: rgba(255, 255, 255, 0.8);
    outline: 2px solid white;
    outline-offset: 2px;
  }
  &:focus:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
