.error {
  visibility: hidden;
  opacity: 0;
  font-size: 1.72rem;
  line-height: 1.9rem;
  color: white;
  font-family: 'ITC Avant Garde Gothic', 'Poppins', sans-serif;
  font-weight: 400;
  margin: 20px;
  transition: visibility 0s ease 300ms, opacity 300ms;
  pointer-events: none;
}

.error-visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 300ms;
}