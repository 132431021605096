
.header {
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 25px;
  overflow: hidden;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0;
}

.center {
  display: flex;
  flex: 1 1 auto;
}

.right {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0;
  white-space: nowrap;
}