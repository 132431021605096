html {
  /* Sets up rem font sizing to be power of 10 */
  /* 1rem = 10px;
  /* 1.2rem = 12px;
  /* 1.4rem = 14px;
  /* https://www.sitepoint.com/understanding-and-using-rem-units-in-css/ */
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

body {
  margin: 0;
  font-family: 'ITC Avant Garde Gothic', 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #DD2C56;
  color: white;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 1.72rem;
}

h1 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 10.4rem;
  line-height: 12rem;
  font-weight: 700;
}

h2 {
  font-size: 6.7rem;
  line-height: 8rem;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 700;
}

p {
  font-size: 1.72rem;
  line-height: 1.9rem;
  margin: 0;
}

span {
  font-size: 1.72rem;
  line-height: 1.9rem;
  margin: 0;
}

a {
  font-size: 1.72rem;
  line-height: 1.9rem;
  margin: 0;
  color: white;
  text-decoration: none;
}

a[disabled]:hover {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input {
  font-size: 1.72rem;
  color: black;
  font-family: 'ITC Avant Garde Gothic', 'Poppins', sans-serif;
  font-weight: 400;
}
