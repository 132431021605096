.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  
  > h2 {
    margin-bottom: 50px;
  }
}

.options {
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
  text-align: center;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    min-width: 300px;
    margin-bottom: 40px;
    > * {
      margin-bottom: 20px;
    }
  }
}
