.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 760px;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  > * {
    margin-bottom: 60px;
  }
  > a {
    margin-top: 60px;
    text-decoration: underline;
  }
}

.errorDescription {
  margin-bottom: 10px !important;
}
